<template>
  <div id="tracking-slip" class="">
    <header class="text-center mt-5">
      <img
        src="@/assets/images/ched-header-logo-big.png"
        width="500"
        height="132"
        alt="CHED Header Logo"
      />
      <h4 class="mt-5 font-weight-bold">DOCUMENT TRACKING SLIP</h4>
    </header>

    <div class="ml-2">
      <p class="mb-1">Control No</p>
      <h4 class="font-weight-bold">DTS-R13-{{ document.id }}</h4>
    </div>

    <table class="table table-bordered mt-5">
      <tbody>
        <tr style="border-top: 0px">
          <td width="15%">Document Type</td>
          <th colspan="3">{{ document.document_type }}</th>
        </tr>
        <tr>
          <td>Document Title</td>
          <th colspan="3">
            {{ document.title }}
          </th>
        </tr>
        <tr>
          <td>Description</td>
          <th colspan="3">
            {{ document.description }}
          </th>
        </tr>
        <tr>
          <td>Remarks</td>
          <th colspan="3">{{ document.remarks }}</th>
        </tr>

        <tr>
          <td width="15%">Origin / Source</td>
          <th width="35%">
            {{ document.origin }}
          </th>
          <td width="15%">Date Received</td>
          <th width="35%">{{ document.created_at | dateTime }}</th>
        </tr>
        <tr>
          <td>Transaction Type</td>
          <th>
            {{ document.transaction_type }}
          </th>
          <td>Deadline</td>
          <th>
            <div v-if="document.deadline !== null">
              {{ document.deadline | dateTime }}

              ({{ document.deadline | dueIn }})
            </div>
            <span v-else>N/A</span>
          </th>
        </tr>

        <tr>
          <td>Desired Action</td>
          <th>
            {{ document.desired_action }}
          </th>
          <td>Encoded by</td>
          <th>{{ document.encoded_by }}</th>
        </tr>
        <tr>
          <td>Status</td>
          <th>
            {{ document.status }}
          </th>
          <td>Date Encoded</td>
          <th>{{ document.created_at | dateTime }}</th>
        </tr>
      </tbody>
    </table>

    <div id="routing-and-receiving-table" class="mt-5">
      <table class="table table-bordered">
        <tbody>
          <tr>
            <th colspan="6">ROUTING AND RECEIVING MATRIX</th>
          </tr>
          <tr>
            <td width="15%">Office/Unit</td>
            <td></td>
            <td width="20%">Name and Signature</td>
            <td></td>
            <td width="15%">Date</td>
            <td></td>
          </tr>
          <tr>
            <td>Office/Unit</td>
            <td></td>
            <td>Name and Signature</td>
            <td></td>
            <td>Date</td>
            <td></td>
          </tr>
          <tr>
            <td>Office/Unit</td>
            <td></td>
            <td>Name and Signature</td>
            <td></td>
            <td>Date</td>
            <td></td>
          </tr>
          <tr>
            <td>Office/Unit</td>
            <td></td>
            <td>Name and Signature</td>
            <td></td>
            <td>Date</td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>

    <footer style="margin-top: 230px;">
      <span>Printed: {{ Date() | dateTime }}</span>
      <hr />
      <div class="text-center">
        <p class="mb-0">
          Higher Education Development Center Building, Brgy. Ampayon, Butuan
          City, Agusan Del Norte
        </p>
        <p>
          <i class="bi bi-globe"></i> chedcaraga.ph
          <i class="bi bi-envelope ml-3"></i> chedcaraga@ched.gov.ph
          <i class="bi bi-telephone ml-3"></i> (085) 817 3101
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'TrackingSlip',
  data() {
    return {
      document: new Form({
        id: '',
        origin: '',
        document_type: '',
        transaction_type: '',
        title: '',
        status: '',
        deadline: '',
        description: '',
        remarks: '',
        path: '',
        created_at: '',
        encoded_by: '',
      }),
    };
  },
  methods: {
    print(document) {
      this.document.fill(document);
      setTimeout(() => {
        this.$htmlToPaper('tracking-slip');
      }, 1000);
    },
  },
};
</script>

const priorityLevels = {
    1: 'text-muted', // low
    2: 'text-warning', // medium
    3: 'text-danger', // high
};

const flagIcon = 'bi bi-flag-fill mr-2';

export function getFlagColor(level) {
  return `${flagIcon} ${priorityLevels[level]}`;
}



export function hasEmptyOrNullProperties (object) {
    if(typeof object !== 'object' ) return;   
    
    for(let key in object) {
        if(!object[key]) {
            if(key == 'extension_name') continue;
            return true;
        }
    }
    
    return false;
}


 

<template>
  <div>
    <div class="modal" id="forward-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="forwardToUser">
            <div class="modal-body">
              <ul class="list-group">
                <li
                  v-for="item in selected"
                  :key="item.id"
                  class="list-group-item"
                >
                  <strong>[DTS-R13-{{ item.id }}]</strong> : {{ item.title }}
                </li>
              </ul>
              <small class="form-text text-muted"
                >You can also forward multiple documents by ticking the
                boxes.</small
              >
              <br />
              <div class="form-group">
                <label for="">Forward to</label>

                <Multiselect
                  v-model="forwarded_to"
                  :options="users"
                  track-by="id"
                  :custom-label="getFullname"
                  placeholder="Choose"
                  :allow-empty="false"
                  required
                ></Multiselect>

                <!--   <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="forwarded_to"
                  required
                >
                  <option value="" selected hidden disabled>Choose</option>
                  <option
                    v-for="user in users"
                    :key="user.id"
                    :value="user.id"
                    >{{ `${user.first_name} ${user.last_name}` }}</option
                  >
                </select> -->
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button id="forward-btn" type="submit" class="btn btn-primary">
                Forward
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'ForwardModal',
  components: {
    Multiselect,
  },
  props: {
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      forwarded_to: {
        first_name: '',
        last_name: '',
        id: '',
      },
      users: [],
    };
  },
  methods: {
    openModal() {
      $('#forward-modal').modal('show');
      this.getInternalUsers();
    },
    forwardToUser() {
      if (!this.forwarded_to.id) {
        toastr.error('Select from the list', 'Error');
        return;
      }
      let b = document.getElementById('forward-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Forwarding...`;

      let params = {
        selected: this.selected,
        forwarded_to: this.forwarded_to.id,
      };

      axios
        .put('api/documents/forward', params)
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          b.innerHTML = 'Forward';
          b.disabled = false;
          $('#forward-modal').modal('hide');
          this.$emit('hasUpdates');
        })
        .catch((err) => {
          toastr.error(err.response.data.message, 'Error');
          b.innerHTML = 'Forward';
          b.disabled = false;
        });
    },

    getInternalUsers() {
      axios
        .get('api/users/internal')
        .then((res) => {
          this.users = res.data;
        })
        .catch((err) => {});
    },

    getFullname({ first_name, last_name }) {
      return `${first_name} ${last_name} `;
    },
  },
  mounted() {},
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <div class="card rounded shadow-sm">
      <div class="card-body">
        <div>
          <button
            class="btn btn-outline-secondary text-dark mr-2"
            @click="openModal"
          >
            <i class="bi bi-folder-plus mr-2"></i> Create new record
          </button>

          <button class="btn btn-danger mr-2" @click="deleteSelectedItems">
            <i class="bi bi-trash mr-2"></i>Delete
          </button>
        </div>

        <br />
        <p>Recently added by you</p>
        <div class="table-responsive">
          <table id="recently-added-table" class="table table-sm table-hover">
            <thead>
              <th></th>
              <th>Tracking No</th>
              <th width="35%">Document Title</th>
              <th>Origin</th>
              <th>Type</th>
              <th>Deadline</th>
              <th>Encoded on</th>
              <th>Status</th>
            </thead>
            <tbody>
              <tr class="text-center" v-if="!documents.length && !tableLoader">
                <td colspan="11">
                  No results found
                </td>
              </tr>
              <tr class="text-center" v-if="tableLoader">
                <td colspan="11">
                  <div class="spinner-border spinner-border-sm mr-1"></div>
                  Loading
                </td>
              </tr>
              <tr
                :class="{ 'table-secondary': d.status == 'Closed' }"
                v-for="d in documents"
                :key="d.id"
                @click="openDocument(d)"
              >
                <td>
                  <input
                    type="checkbox"
                    :value="d"
                    style="width: 15px; height: 15px;"
                    v-model="selectedItems"
                    :disabled="d.status == 'Closed'"
                    @click.stop
                  />
                </td>
                <td>
                  DTS-R13-{{ d.id }}
                  <span v-if="d.is_incoming">
                    <i class="bi bi-arrow-down"></i>
                  </span>
                  <span v-else> <i class="bi bi-arrow-up"></i> </span>
                </td>
                <td style="font-weight:500;">
                  <i :class="[getFlagColor(d.priority_level)]"></i>{{ d.title }}
                </td>

                <td>{{ d.origin }}</td>
                <td>{{ d.document_type }}</td>

                <td>
                  <div v-if="d.deadline !== null">
                    {{ d.deadline | dateTime }}
                    <small class="form-text text-muted"
                      >{{ d.deadline | dueIn }} ({{
                        d.transaction_type
                      }})</small
                    >
                  </div>
                  <span v-else>N/A</span>
                </td>
                <td>
                  {{ d.created_at | completeDateTime }}
                </td>
                <td>
                  <span
                    v-if="d.status == 'Pending'"
                    class="badge badge-pill badge-danger"
                    >{{ d.status }}</span
                  >
                  <span v-else class="badge badge-pill badge-secondary">{{
                    d.status
                  }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div class="modal" data-backdrop="static" id="create-record-modal">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <form @submit.prevent="createRecord">
            <div class="modal-body">
              <div class="form-group">
                <div class="btn-group btn-group-toggle w-100">
                  <label
                    id="incoming-btn"
                    class="btn btn-outline-secondary active"
                  >
                    <input type="radio" @click="toIncoming" />
                    Incoming <i class="bi bi-arrow-down"></i>
                  </label>
                  <label id="outgoing-btn" class="btn btn-outline-secondary">
                    <input type="radio" @click="toOutgoing" />
                    Outgoing <i class="bi bi-arrow-up"></i>
                  </label>
                </div>
              </div>

              <div class="form-group" v-if="!hideDateTime">
                <label for="">Date and Time Received</label>
                <input
                  type="datetime-local"
                  class="form-control"
                  v-model="form.date_received_for_encoding"
                  required
                />
                <small class="form-text text-muted">
                  Official date and time of receiving via email/physical.
                </small>
              </div>
              <div class="form-group" v-if="!hideOrigin">
                <label for="">Origin</label>

                <input
                  type="text"
                  class="form-control"
                  v-model="form.origin"
                  required
                />
              </div>
              <div class="form-group">
                <label for="">Document Type</label>
                <select
                  class="form-control"
                  v-model="form.document_type"
                  required
                >
                  <option value="" selected disabled hidden>Choose</option>
                  <option value="Acknowledgement">Acknowledgement</option>
                  <option value="Complaint">Complaint</option>
                  <option value="Compliance">Compliance</option>
                  <option value="Data Request">Data Request</option>
                  <option value="Deficiency">Deficiency</option>
                  <option value="Information">Information</option>
                  <option value="Meeting">Meeting</option>
                  <option value="Memorandum">Memorandum</option>
                  <option value="Orientation">Orientation</option>
                  <option value="Program Application">Program App.</option>
                  <option value="Letter">Letter</option>
                  <option value="Indorsement">Indorsement</option>
                  <option value="Invitation">Invitation</option>
                  <option value="Issuance">Issuance</option>
                  <option value="Report">Report</option>
                  <option value="Request">Request</option>
                  <option value="Scholarship">Scholarship</option>
                  <option value="UNIFAST">UNIFAST</option>
                  <option value="Special Order">Special Order</option>
                  <option value="Training">Training</option>
                  <option value="Voucher">Voucher</option>
                </select>
              </div>
              <div class="form-row">
                <div class="col">
                  <div class="form-group" v-if="!hideTransactionType">
                    <label for="">Transaction Type</label>
                    <select
                      class="form-control"
                      v-model="form.transaction_type"
                      @change="setDeadline"
                      :disabled="!form.date_received_for_encoding"
                      required
                    >
                      <option value="" disabled hidden selected>Choose</option>
                      <option value="Simple">Simple (3 Days)</option>
                      <option value="Complex">Complex (7 Days)</option>
                      <option value="Highly Tech."
                        >Highly Technical (21 Days)</option
                      >
                      <option value="N/A">N/A</option>
                    </select>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group" v-if="!hideTransactionType">
                    <label for="">Priority</label>
                    <select class="form-control" v-model="form.priority_level">
                      <option value="1">Low</option>
                      <option value="2">Medium</option>

                      <option value="3">High</option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="">Title</label>
                <textarea
                  rows="2"
                  class="form-control"
                  v-model="form.title"
                  required
                ></textarea>
                <small class="form-text text-muted"
                  >Make sure to capture all important details of the document.
                </small>
              </div>

              <div class="form-group">
                <label for="">Remarks (Optional)</label>
                <textarea
                  rows="2"
                  type="text"
                  class="form-control"
                  v-model="form.remarks"
                />
                <small class="form-text text-muted"
                  >Insert any additional comments, notes, instructions, and etc.
                </small>
              </div>

              <div class="form-group">
                <label for="">Scanned file</label>
                <input
                  type="file"
                  id="file-input"
                  class="form-control-file"
                  accept=".doc, .docx, .png, .jpeg, application/pdf"
                  @change="getFile"
                  required
                />
                <small class="form-text text-muted"
                  >File types: docs, pdf, images</small
                >
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link" data-dismiss="modal">
                Close
              </button>
              <button id="submit-btn" type="submit" class="btn btn-primary">
                Create
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <DocumentDetailsModal
      ref="DocumentDetailsModal"
      :canAddActionTaken="false"
    ></DocumentDetailsModal>
  </div>
</template>

<script>
import DocumentDetailsModal from './DocumentDetailsModal.vue';
import { getFlagColor } from '../../../js/helpers';

export default {
  name: 'CreateRecord',
  components: {
    DocumentDetailsModal,
  },
  data() {
    return {
      documents: [],
      selectedItems: [],
      tableLoader: true,
      hideTransactionType: false,
      hideOrigin: false,
      hideDateTime: false,
      user: this.$store.state.user,
      file: '',
      form: new Form({
        id: '',
        date_received_for_encoding: '',
        owner_id: '',
        encoded_by: '',
        is_incoming: '',
        origin: '',
        document_type: '',
        transaction_type: '',
        priority_level: '1',
        title: '',
        remarks: '',
        deadline: '',
      }),
    };
  },
  methods: {
    openDocument(doc) {
      this.$refs.DocumentDetailsModal.show(doc);
    },
    openModal() {
      this.inUpdateMode = false;
      this.selectedItems = [];
      document.getElementById('file-input').value = null;
      this.file = '';
      this.toIncoming();
      $('#create-record-modal').modal('show');
    },
    setDeadline() {
      let transactions = {
        Simple: 3,
        Complex: 7,
        'Highly Tech.': 21,
        'N/A': 'N/A',
      };

      if (this.form.transaction_type == 'N/A') return;

      let date = moment(this.form.date_received_for_encoding).businessAdd(
        transactions[this.form.transaction_type]
      );
      return (this.form.deadline = moment(date).format('YYYY-MM-DD HH:mm:ss'));
    },
    createRecord() {
      let b = document.getElementById('submit-btn');
      b.disabled = true;
      b.innerHTML = `<span class="spinner-border spinner-border-sm mr-1"></span> Creating...`;

      let fd = new FormData();

      for (let key in this.form) {
        fd.append(key, this.form[key]);
      }

      fd.append('file', this.file);

      axios
        .post('api/documents', fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          toastr.success(res.data.msg, 'Information');
          b.innerHTML = 'Create';
          b.disabled = false;
          $('#create-record-modal').modal('hide');
          this.getMyDocuments(false);
        })
        .catch((err) => {
          console.log(err);
          b.innerHTML = 'Create';
          b.disabled = false;
          toastr.error(err.response.data.message, 'Error');
        });
    },
    deleteSelectedItems() {
      if (!this.selectedItems.length) {
        toastr.error('No document selected', 'Error');
        return;
      }

      let items = [];

      this.selectedItems.forEach((i) => {
        items.push('DTS-R13-' + i.id);
      });

      if (
        confirm(
          `Are you sure you want to delete the selected item(s)? \nDocument No(s): ${items.toString()}          
          \nWARNING: This action cannot be undone.
          `
        )
      ) {
        axios
          .post('api/documents/delete-selected-items', this.selectedItems)
          .then((res) => {
            this.getMyDocuments(false);
          })
          .catch((err) => {});
      }
    },
    getFile(event) {
      this.file = event.target.files[0];
    },
    toIncoming() {
      this.form.reset();
      this.form.owner_id = this.user.id;
      this.form.encoded_by = this.user.id;
      this.form.is_incoming = 1;
      this.hideTransactionType = false;
      this.hideDateTime = false;
      this.hideOrigin = false;
      $('#outgoing-btn').removeClass('active');
      $('#incoming-btn').addClass('active');
    },
    toOutgoing() {
      this.form.reset();
      this.form.is_incoming = 0;
      this.hideTransactionType = true;
      this.hideOrigin = true;
      this.hideDateTime = true;
      this.form.origin = 'CHED Caraga';
      this.form.transaction_type = 'N/A';
      this.form.deadline = '';
      this.form.owner_id = this.user.id;
      this.form.encoded_by = this.user.id;
      $('#incoming-btn').removeClass('active');
      $('#outgoing-btn').addClass('active');
    },
    getMyDocuments(toggle) {
      this.tableLoader = toggle;
      axios.get('api/documents/my-documents').then((res) => {
        this.documents = res.data.data;
        this.tableLoader = false;
      });
    },
    getFlagColor,
  },
  mounted() {
    this.getMyDocuments(true);
  },
};
</script>

<style scoped>
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: white;
  background-color: #2a1ab9;
  border-color: #2819ae;
}
#recently-added-table tr {
  cursor: pointer !important;
}
</style>
